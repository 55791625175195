/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";

import { AppContext } from "~context/AppContext";

import Layout from "~components/Layout";
import SEO from "~components/SEO";
import {
  fancyError,
  fancyLog,
  fancyWarning,
  validateEmail
} from "~utils/helpers";
import Banner from "~components/Banner";

class ContactPageComponent extends Component {
  state = {
    form: {},
    submitted: false,
    submitting: false
  };

  requiredInputs = [`first_name`, `last_name`, `email`, `message`];

  componentDidMount() {
    const { appContext } = this.props;

    appContext.setMenuActive(false);
    appContext.setNavText(`Back`);
  }

  //

  onInputChange = e => {
    const { form } = this.state;
    const { value } = e.target;
    const key = e.target.getAttribute(`name`);

    if (!form[key]) {
      form[key] = {};
    }

    form[key].value = value;
    form[key].interacted = value !== ``;

    switch (key) {
      case `email`:
        form.email.valid = validateEmail(value);

        break;

      case `first_name`:
      case `last_name`:
      case `message`:
        form[key].valid = value !== ``;

        break;

      default:
        break;
    }

    this.setState({ form });
  };

  //

  submit = e => {
    e.preventDefault();

    if (!this.validate()) {
      return false;
    }

    this.setState({
      submitting: true
    });

    const { form } = this.state;

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/email`, {
      body: JSON.stringify(form),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Email Sent`);

        this.setState({
          submitted: true,
          submitting: false
        });
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  validate = () => {
    let valid = true;

    const { form } = this.state;

    this.requiredInputs.forEach(requiredInput => {
      if (!form[requiredInput]) {
        form[requiredInput] = {
          interacted: true,
          valid: false,
          value: ``
        };

        valid = false;
      }
    });

    if (!valid) {
      this.setState({
        form
      });

      fancyWarning(`Required form values missing`);
    } else {
      Object.keys(this.state.form).forEach(inputKey => {
        if (!valid) {
          return;
        }

        if (this.state.form[inputKey].valid === false) {
          fancyWarning(`Invalid form value: ${inputKey}`);

          valid = false;
        }
      });
    }

    return valid;
  };

  //

  render() {
    const { bannerMedia } = this.props.data.markdownRemark.frontmatter;

    return (
      <>
        <SEO pageTitle="Contact" pathName="/contact" />

        <Layout className="contact-page w-full bg-sun-yellow gpu">
          <Banner
            bannerImage={bannerMedia.src.childImageSharp.fluid}
            bannerText={bannerMedia.bannerText}
          />

          <section className="relative pt-8 pb-40 xs:pb-24 grid">
            <h2
              className="animation-appear grid-end-8 xs:grid-end-11 mb-12 d2 font-medium"
              style={{ animationDelay: `150ms` }}
            >
              Here&apos;s a place for you to say it. Send us an email:
              <br />
              <a className="font-normal" href="mailto:bunnies@itsallfluff.com">
                bunnies@itsallfluff.com
              </a>
            </h2>

            {/* TODO: When site is on netlify use the below form */}
            {/* {(this.state.submitted && (
              <section className="form animation-appear relative grid">
                <h3 className="grid-end-8 xs:grid-end-12 f2">
                  Y: info received. We’ll be in touch soon. Check your inbox.
                </h3>
              </section>
            )) || (
              <form
                className={`form relative grid ${this.state.submitting &&
                  `submitting`}`}
                onSubmit={this.submit}
              >
                <p
                  className="animation-appear grid-end-8 xs:grid-end-11 mb-12 d2 font-medium"
                  style={{ animationDelay: `150ms` }}
                >
                  Type some words into these boxes and we’ll be in touch.
                </p>

                <input
                  id="first_name"
                  name="first_name"
                  className="form__input animation-appear grid-end-4 grid-start-1 xs:grid-end-12 relative block mt-2 pl-1 f3 font-medium"
                  style={{ animationDelay: `200ms` }}
                  onChange={this.onInputChange}
                  placeholder="Full Name*"
                  required
                  type="text"
                />

                <input
                  id="last_name"
                  name="last_name"
                  className="form__input animation-appear grid-end-4 xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1 f3 font-medium"
                  style={{ animationDelay: `200ms` }}
                  onChange={this.onInputChange}
                  placeholder="Last Name*"
                  required
                  type="text"
                />

                <input
                  id="email"
                  name="email"
                  className="form__input animation-appear grid-end-8 grid-start-1 xs:grid-end-12 relative block mt-2 pl-1 f3 font-medium"
                  style={{ animationDelay: `250ms` }}
                  onChange={this.onInputChange}
                  placeholder="Email*"
                  required
                  type="email"
                />

                <textarea
                  id="message"
                  name="message"
                  className="form__input animation-appear grid-end-8 grid-start-1 xs:grid-end-12 relative block mt-5 pl-1 pb-1 f3 font-medium"
                  style={{ animationDelay: `300ms` }}
                  onChange={this.onInputChange}
                  placeholder="Message*"
                  required
                  type="text"
                />

                <div
                  className="animation-appear grid-end-8 xs:grid-end-10 xs:grid-start-2 flex items-end justify-end mt-4 b2 font-medium"
                  style={{ animationDelay: `350ms` }}
                >
                  <input
                    className="form__submit py-2 cursor-pointer"
                    type="submit"
                    value={`${this.state.submitting ? `SUBMITTING` : `SUBMIT`}`}
                  />
                </div>
              </form>
            )} */}
          </section>
        </Layout>
      </>
    );
  }
}

const ContactPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <ContactPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
  }
`;
